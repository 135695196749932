import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
          click: {
            open: 'open',
            close: 'close',
          }
        }
    }

    init() {

    }

    open() {

    }

    close() {
      
    }
}
