/**
 * External Dependencies
 */
import 'jquery';

import './locomotive/app';

$(() => {
  //console.log('Hello world');
});
