import { module } from 'modujs';
import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination]);

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
      var container = '.c-slider_container';
      var items = 1;
      var pagination = false;
      var navigation = false;
      var breakpoints = [];

      if (this.getData('container')) {
        container = this.getData('container');
      }

      if (this.getData('items')) {
        items = this.getData('items');
      }

      if (this.getData('pagination')) {
        var renderBullet = false;
        if(this.getData('pagination-number')) {
          renderBullet = function (index, className) {
            return `<span class="swiper-pagination-bullet"><span class="dot-number">${index + 1}</span></span>`;
          };
        }
        pagination = {
          el: this.getData('pagination') + '-pagination',
          clickable: true,
          renderBullet: renderBullet,
        };
      }

      if (this.getData('navigation')) {
        navigation = {
          nextEl: this.getData('navigation') + '-next',
          prevEl: this.getData('navigation') + '-prev',
        };
      }

      if (this.getData('breakpoints')) {
        breakpoints = JSON.parse(this.getData('breakpoints'));
      }

      new Swiper(container, {
        slidesPerView: parseInt(items),
        // Optional parameters
        direction: 'horizontal',
        watchSlidesVisibility: true,
        //loop: true,
        autoHeight: false,

        // If we need pagination
        pagination: pagination,

        // Navigation arrows
        navigation: navigation,

        breakpoints: breakpoints,
      });
    }
}
