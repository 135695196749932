import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                close: 'close',
                toggle: 'toggle',
            }
        }
    }

    init() {

    }

    close() {
        const html = document.querySelector('html');
        const burger = document.querySelector('.c-header_nav_burger');
        const text = burger.querySelector('.-hover');
        html.classList.remove('has-navOpen');
        burger.classList.remove('is-active');
        text.textContent = text.dataset.texthover;
    }

    toggle(e) {
        let el = e.currentTarget;
        let classes = $(el).parent('.c-nav_item_wrap').attr('class');
        if(classes.indexOf('is-open') != -1) {
            $(el).parent('.c-nav_item_wrap').removeClass('is-open');
        }else {
            $('.c-nav_item_wrap').removeClass('is-open');
            $(el).parent('.c-nav_item_wrap').addClass('is-open');
        }
    }
}
